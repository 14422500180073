<template>
  <div class="inspection-details-container">
    <h1 class="title is-1">Alapadatok</h1>
    <div class="columns is-multiline">
      <div class="column is-12 is-6-desktop">
        <CompanyDetailsCard/>
      </div>
      <div class="column is-12 is-6-desktop">
        <CommissionCard/>
      </div>
      <div class="column is-12 is-6-desktop">
        <InspectionLocationCard/>
      </div>
      <div class="column is-12 is-6-desktop">
        <QuestionnairesCard/>
      </div>
    </div>
  </div>
</template>

<script>
import CompanyDetailsCard from '@/components/Cards/CompanyDetailsCard'
import CommissionCard from '@/components/Cards/CommissionCard'
import InspectionLocationCard from '@/components/Cards/InspectionLocationCard'
import QuestionnairesCard from '@/components/Cards/QuestionnairesCard'

export default {
  name: 'InspectionDetails',
  components: {
    CompanyDetailsCard,
    CommissionCard,
    InspectionLocationCard,
    QuestionnairesCard,
  },
}
</script>

<style scoped>
.inspection-details-container {
  max-width: 1200px;
}
</style>
