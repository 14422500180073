<template>
  <Card title="Megbízások">
    <template v-slot:body>
      <div v-for="(commission, index) in $store.state.inspection.selected.details.commissions"
           :key="index">
        <div class="">
          <h4 class="has-text-weight-normal">{{ index + 1 }}. megbízás</h4>
          <h6 class="mb-0">Név</h6>
          <div>{{ commission.name }}</div>
          <h6 class="mb-0">Kérdőív</h6>
          <div>{{ commission.survey_name }}</div>
          <h6 class="mb-0">Vizsgált időszak</h6>
          <div>{{ commission.year }}</div>
          <div class="divider my-2"/>
        </div>
      </div>
      <strong v-if="$store.state.inspection.selected.details.commissions.length === 0">
        Nincs ellenőrzésre kiválasztott megbízás
      </strong>
    </template>
  </Card>
</template>

<script>
import Card from '@/components/Cards/Common/Card'

export default {
  name: 'CommissionCard',
  components: { Card },
}
</script>

<style scoped>

</style>
