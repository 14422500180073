<template>
  <Card title="Egyéb kérdőívek">
    <template v-slot:body>
      <div v-for="(survey, index) in $store.state.inspection.selected.details.other_surveys"
           :key="index">
        {{ survey.name }}
      </div>
      <strong v-if="$store.state.inspection.selected.details.other_surveys.length === 0">
        Nincsenek kérdőívek
      </strong>
    </template>
  </Card>
</template>

<script>
import Card from '@/components/Cards/Common/Card'

export default {
  name: 'QuestionnairesCard',
  components: { Card },
}
</script>

<style scoped>

</style>
