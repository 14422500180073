<template>
  <Card title="Ellenőrzések helyszíne, időpontja">
    <template v-slot:body>
      <div class="columns is-multiline">
        <div class="column py-1 is-12 is-4-desktop">
          <h6 class="mb-0">Helység</h6>
        </div>
        <div class="column py-1 is-12 is-8-desktop">
          {{ $store.state.inspection.selected.details.inspection_details.address.city }}
        </div>
        <div class="column py-1 is-12 is-4-desktop">
          <h6 class="mb-0">Utca, házszám</h6>
        </div>
        <div class="column py-1 is-12 is-8-desktop">
          <span>{{ $store.state.inspection.selected.details.inspection_details.address.street }}</span>
          <b-button class="text-button-height-modifier"
                    @click="$store.dispatch(
                    'openModal',
                  { modalName: 'GoogleMapsModal',
                           data: { address: $store.state.inspection.selected.details.inspection_details.address.inline_address }
                    })"
                    icon-left="map-pin"
                    type="is-ghost">
            Mutat
          </b-button>
        </div>
        <div class="column py-1 is-12 is-4-desktop">
          <h6 class="mb-0">Dátum</h6>
        </div>
        <div class="column py-1 is-12 is-8-desktop">
          <div>{{ $store.state.inspection.selected.details.inspection_details.date }}</div>
          <div>
            <b-button type="is-ghost"
                      @click="addEventToGoogleCalendarBtnClick"
                      class="pl-0"
                      icon-pack="fab"
                      icon-left="google">
              Calendar hozzáadás
            </b-button>
          </div>
          <div>
            <b-button type="is-ghost"
                      @click="eventICalendarDownloadBtnClick"
                      class="pl-0"
                      icon-left="calendar-plus">
              ICal letöltés
            </b-button>
          </div>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import Card from '@/components/Cards/Common/Card'
import { addEventToGoogleCalendar, eventICalendarDownload } from '@/utils/utils'

export default {
  name: 'InspectionLocationCard',
  components: { Card },
  methods: {
    addEventToGoogleCalendarBtnClick () {
      addEventToGoogleCalendar(
        `${this.$store.state.inspection.selected.details.member.name} köyvvizsgálati ellenőrzés`,
        this.$store.state.inspection.selected.details.inspection_details.date,
        this.$store.state.inspection.selected.details.inspection_details.address.inline_address,
      )
    },
    eventICalendarDownloadBtnClick () {
      eventICalendarDownload(
        `${this.$store.state.inspection.selected.details.member.name} köyvvizsgálati ellenőrzés`,
        this.$store.state.inspection.selected.details.inspection_details.date,
        this.$store.state.inspection.selected.details.inspection_details.address.inline_address,
      )
    },
  },
}
</script>

<style scoped>

</style>
