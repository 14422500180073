<template>
  <div class="card">
    <header class="card-header has-background-success ">
      <h2 class="title is-4 card-header-title has-text-white">
        {{ title }}
      </h2>
      <!--        <button class="card-header-icon" aria-label="more options">-->
      <!--      <span class="icon">-->
      <!--        <i class="fas fa-angle-down" aria-hidden="true"></i>-->
      <!--      </span>-->
      <!--        </button>-->
    </header>
    <div class="card-content">
      <div class="content">
        <slot name="body">Card body</slot>
      </div>
    </div>
    <footer v-if="$slots.footer"
            class="card-footer">
      <slot name="footer"></slot>
      <!--      <a href="#" class="card-footer-item">Save</a>-->
      <!--      <a href="#" class="card-footer-item">Edit</a>-->
      <!--      <a href="#" class="card-footer-item">Delete</a>-->
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  mounted () {
  },
}
</script>

<style scoped>
.card {
  height: 100%;
}
</style>
